import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import Layout from "../../../../components/layout";
import SEO from "../../../../components/seo";
import * as CONST from "../../../../components/const";
const kv = "../../../../images/business/solution/use_conservative/keitahaginiwa/kv_rc.png";
const content1 = "../../../../images/business/solution/use_conservative/keitahaginiwa/img1_rc.png";
const content2 = "../../../../images/business/solution/use_conservative/keitahaginiwa/img2_rc.png";

function EltexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

	return (
		< Layout >
			<SEO
				description="ALPHA WAVEの運用保守サービスの実績です。株式会社萩庭桂太写真事務所様の例をご覧ください。"
				title="株式会社萩庭桂太写真事務所様"
			/>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
					<div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}>
						<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Business content <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>事業内容</span></p>
					</div>
					<div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
						<div>
							<p>株式会社萩庭桂太写真事務所様より～ご依頼をいただきました。 </p>
						</div>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={kv} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-12/12">
					<h2 className={CONST.H2_CLASS}>株式会社萩庭桂太写真事務所様</h2>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<h4 className={CONST.H4_CLASS}>お客様からの依頼内容</h4>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={content1} />
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={content2} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<div>
						<h4 className={CONST.H4_CLASS}>弊社の対応内容</h4>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS + " justify-center"}>
				<Link to="../../">実績トップ</Link>
			</section>

		</Layout >
	);
}

export default EltexPage;
